.navbar-brand {
	margin: 1rem;
}

.page-wrapper {
	height: 100%;
	padding-top: 10em;
	padding-bottom: 5em;
}

.button-wrapper {
	margin-top: 2rem;
}

.single-content-centered {
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Mobile menu */
.ui.menu .item.mobile-menu-dropdown > i.icon {
	margin: 0 !important;
	width: unset !important;
	height: unset !important;
	padding: 0 15px;
}

.ui.menu .item.mobile-menu-dropdown > i.icon::before {
	font-size: 1.5em !important;
}

.mobile-menu-dropdown .item {
	min-width: 200px;
}

/* Order form selector */
.order-form-selector .menu {
	max-height: 300px !important;
}

.segment-rectangle {
	border-radius: 0 !important;
	padding: 1.5em !important;
}

/* Order items */
.delete-item-button {
	text-align: right;
}

/* Fade-out-fade-in animation for components */
.fade-wait-leave {
	opacity: 1;
}
.fade-wait-leave.fade-wait-leave-active {
	opacity: 0;
	transition: opacity 0.2s linear;
}

.fade-wait-enter {
	opacity: 0;
}
.fade-wait-enter.fade-wait-enter-active {
	opacity: 1;
	transition: opacity 0.2s linear 0.8s;
}

.fade-wait-height {
	transition: height 0.6s ease-in-out;
}


.single-order-view label, .user-profile-view label {
	font-weight: 700;
}

.single-order-view .checkbox label {
	font-weight: 400;
}

.single-order-view .column p, .user-profile-view .column p {
	margin-top: 1rem;
	white-space: pre-line;
}

.ui.table th.not-sortable:hover {
	cursor: default;
	background: #F9FAFB !important;
}

.ui.checkbox:hover input.hidden + label {
	cursor: default;
}

.ui.form input:read-only,
.ui form textarea:read-only {
	border: none !important;
}

.ui.pagination.menu .item {
	justify-content: center !important;
}

.not-seen {
	font-weight: bold;
}

.single-order-actions button {
	margin-left: 1rem !important;
}

.order-archive-tabs .menu {
	justify-content: flex-start !important;
}

.order-archive-tabs > .menu > .item.active {
	font-weight: 700;
}

.ui.yellow.label.is-control-label {
	color: black !important;
}

/* Change order archive tab menu item layout on mobile */
@media only screen and (max-width: 767px) {
	.order-archive-tabs > .menu {
		flex-flow: column;
	}

	.mobile-list-item {
		background-color: #eeeeee !important;
	}

	.mobile-list-item.not-seen {
		font-weight: normal;
		background-color: #ffffff !important;
	}
}